// polyfill for the inert attribute
import 'wicg-inert';

// Based on https://github.com/filamentgroup/fg-modal
export default class Modal extends HTMLElement {
  constructor() {
    super();
    this.notificationsWrapperSelector = '.js-notification-wrapper';
    this.modalWrapperSelector = '.js-modal-wrapper';
    this.closeButtonSelector = '.js-close-button';
    this.titleSelector = '.js-title';
    this.bodySelector = 'body';
    this.mainSelector = '.js-main';

    this.hasModalOpenModifier = 'abl-has-opened-modal';
    this.openedModifier = 'abl-opened';

    this.triggerSelector = `.js-modal-trigger[data-uid='${this.id}']`;
    this.closed = true;
    this.triggerElements = [];
    this.customEventsMap = new Map();
    this.customEventNames = [
      'modalInit',
      'modalOpen',
      'modalClose',
      'modalBeforeOpen',
      'modalBeforeClose'
    ];

    this.triggerAttributes = new Map([
      ['aria-haspopup', true],
      ['aria-controls', this.id]
    ]);

    this.modalAttributes = new Map([
      ['role', 'dialog'],
      ['aria-modal', true]
    ]);
  }

  init() {
    this.initElements();
    this.initEvents();
    this.initAttributes();

    this.customEventNames.forEach(eventName => {
      this.customEventsMap.set(eventName, this.makeEvent(eventName));
    });

    this._observer = new MutationObserver(this.init);

    this.dispatchEvent(this.customEventsMap.get('modalInit'));
  }

  /*
  Gather the needed elements
  */
  initElements() {
    this.activeElement = document.activeElement;
    this.window = window;

    this.bodyElement = document.querySelector(this.bodySelector);
    this.mainElement = document.querySelector(this.mainSelector);
    this.modalWrapperElement = document.querySelector(
      this.modalWrapperSelector
    );
    this.notificationsWrapperElement = document.querySelector(
      this.notificationsWrapperSelector
    );

    this.titleElement = this.querySelector(this.titleSelector);
    this.closeBtn = this.querySelector(this.closeButtonSelector);

    this.triggerElements = document.querySelectorAll(this.triggerSelector);
  }

  /*
  Set the attributes for the modal, modal title & trigger links based on the attributes map in the constructor
  */
  initAttributes() {
    if (this.triggerElements.length > 0) {
      this.triggerElements.forEach(triggerElement => {
        this.setAttributesFromMap(triggerElement, this.triggerAttributes);
      });
    }
    this.setAttributesFromMap(this, this.modalAttributes);

    if (this.titleElement) {
      // build title ID based on the modal ID
      this.titleElement.id = `modal_title-${this.id}`;
      this.setAttribute('aria-labelledby', this.titleElement.id);
    }
  }

  /*
  maps the attributes to an element
  */
  setAttributesFromMap(element, attributesMap) {
    for (const [attribute, value] of attributesMap) {
      element.setAttribute(attribute, value);
    }
  }

  /*
  initialize the events the modal need to react on
  */
  initEvents() {
    this.closeBtn.addEventListener('click', this.onCloseButtonClick.bind(this));
    this.window.addEventListener('click', this.onWindowClick.bind(this));
    this.window.addEventListener('focusin', this.onWindowFocus.bind(this));
    this.window.addEventListener('keydown', this.onWindowKeydown.bind(this));
    this.window.addEventListener('mouseup', this.onWindowMouseUp.bind(this));
    this.window.addEventListener(
      'beforeOpen',
      this.onWindowBeforeOpen.bind(this)
    );
  }

  onWindowClick(event) {
    // open modal on click
    this.matchModalTrigger(event.target, event);
  }

  onWindowKeydown(event) {
    // open modal on space
    const key = event.key;
    if (key === ' ' || key === 'Spacebar') {
      this.matchModalTrigger(event.target);
    }

    // close on escape
    if ((key === 'Escape' || key === 'Esc') && !this.closed) {
      event.preventDefault();
      this.close();
    }
  }

  onWindowFocus(event) {
    this.activeElement = event.target;
  }

  onWindowMouseUp(event) {
    // click on anything outside dialog closes it too (if screen is not shown maybe?)
    if (!this.closed && !this.closest(event.target, `#${this.id}`)) {
      event.preventDefault();
      this.close();
    }
  }

  onWindowBeforeOpen(event) {
    if (!this.closed && event.target !== this) {
      this.close(true);
    }
  }

  onCloseButtonClick(event) {
    this.close();
  }

  /*
  create custom events that the component emits with this.dispatchEvent()
  see this.customEventNames for detail
  */
  makeEvent(eventName) {
    if (typeof window.CustomEvent === 'function') {
      return new CustomEvent(eventName, {
        bubbles: true,
        cancelable: false
      });
    } else {
      const event = document.createEvent('CustomEvent');
      event.initCustomEvent(eventName, true, true, {});
      return event;
    }
  }

  /*
  pairs the modal with its trigger based on the id
  The event listener is attached to the window and walks up the tree recoursivly to find a matching trigger
  */
  matchModalTrigger(targetNode, event = null) {
    const assocLink = this.closest(targetNode, this.triggerSelector);
    if (assocLink) {
      this.open();

      if (event) {
        event.preventDefault();
      }
    }
  }

  /*
  Recursive tree walking method
  */
  closest(el, s) {
    const whichMatches =
      Element.prototype.matches || Element.prototype.msMatchesSelector;
    do {
      // Check if `el` is a valid Element and then call `matches`
      if (el instanceof Element && whichMatches.call(el, s)) return el;
      if (el) el = el.parentElement || el.parentNode;
    } while (el && el !== null && el.nodeType === 1);
    return null;
  }

  /*
  Opens the modal with setting classes and attributes on itself, main, body and notificationsWrapper
  emits necessary events & delegates focus
  */
  open(programmedOpen) {
    this.dispatchEvent(this.customEventsMap.get('modalBeforeOpen'));
    this.classList.add(this.openedModifier);
    this.bodyElement.classList.add(this.hasModalOpenModifier);
    this.mainElement.setAttribute('aria-hidden', true);
    this.mainElement.inert = true;
    this.notificationsWrapperElement.setAttribute('aria-hidden', true);
    this.notificationsWrapperElement.inert = true;

    if (!programmedOpen) {
      this.focusedElement = this.activeElement;
    }
    this.closed = false;
    this.focus();
    this.dispatchEvent(this.customEventsMap.get('modalOpen'));
  }

  /*
  closes the modal with setting classes and attributes on itself, main, body and notificationsWrapper
  emits necessary events & delegates focus
  */
  close(programmedClose) {
    this.dispatchEvent(this.customEventsMap.get('modalBeforeClose'));
    this.classList.remove(this.openedModifier);
    this.bodyElement.classList.remove(this.hasModalOpenModifier);
    this.mainElement.setAttribute('aria-hidden', false);
    this.mainElement.inert = false;
    this.notificationsWrapperElement.setAttribute('aria-hidden', false);
    this.notificationsWrapperElement.inert = false;
    this.closed = true;
    const focusedElementModal = this.closest(this.focusedElement, '.modal');
    if (focusedElementModal) {
      focusedElementModal.open(true);
    }
    if (!programmedClose) {
      if (this.focusedElement) this.focusedElement.focus();
    }

    this.dispatchEvent(this.customEventsMap.get('modalClose'));
  }

  /*
  is tracking the changes of the DOM inside the modal, so when anything changes on the DOM this.init() is called again
  */
  connectedCallback() {
    if (this.children.length) {
      this.init();
    }
    this._observer.observe(this, { childList: true });
  }

  disconnectedCallback() {
    this._observer.disconnect();
  }
}
