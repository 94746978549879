import Modal from './../abl-modal/abl-modal';
export default class YoutubeModal extends Modal {
  constructor() {
    super();
    this.triggerSelector = '.js-modal-trigger[data-youtube-id]';
    this.contentSelector = '.js-content';

    this.contentElement = this.querySelector(this.contentSelector);

    this.triggerAttributes = new Map([
      ['role', 'button'],
      ['aria-haspopup', true],
      ['aria-controls', this.id]
    ]);
  }

  init() {
    super.init();

    /*
    see if a trigger contains the data-autoplay attribute
    if yes open the paired modal and break the loop
    since opening two modals is not allowed we take the first we encounter
    */
    for (let i = 0; i < this.triggerElements.length; i++) {
      const triggerElement = this.triggerElements[i];
      if (triggerElement.hasAttribute('data-autoplay')) {
        this.matchModalTrigger(triggerElement);
        break;
      }
    }
  }

  close() {
    // Clear modal iframe to stop sound playback after the modal is closed
    this.contentElement.innerHTML = '';
    super.close();
  }

  /*
  pairs the modal with its trigger based on the id
  The event listener is attached to the window and walks up the tree recoursivly to find a matching trigger
  */
  matchModalTrigger(targetNode, event = null) {
    const assocLink = this.closest(targetNode, this.triggerSelector);
    if (assocLink) {
      const youtubeId = assocLink.dataset.youtubeId;
      const youtubeTitle = assocLink.dataset.youtubeTitle;

      if (youtubeId) {
        this.setYoutubeContent(youtubeId, youtubeTitle);
        this.open();

        if (event) {
          event.preventDefault();
        }
      }
    }
  }

  /*
  sets the content for the youtube modal
  */
  setYoutubeContent(youtubeId, youtubeTitle) {
    this.contentElement.innerHTML = '';
    const youtubeIframe = document.createElement('iframe');
    const urlParams = '?enablejsapi=1';
    youtubeIframe.src = `//www.youtube-nocookie.com/embed/${youtubeId}${urlParams}`;
    youtubeIframe.setAttribute('frameborder', 0);
    youtubeIframe.setAttribute('allowfullscreen', true);
    youtubeIframe.className = 'abl-ratio-fill';
    this.contentElement.appendChild(youtubeIframe);

    if (youtubeTitle) {
      this.titleElement.innerText = youtubeTitle;
    }

    // Let Matomo scan and start to track the new YT video iframe
    window._paq = window._paq || [];
    window._paq.push(['MediaAnalytics::scanForMedia', this.contentElement]);
  }
}
